import Bands from 'components/DemoLandingPage/Bands';
import { ReactNode } from 'react';
import { imageBasePath } from 'utils/imageUtils';

export interface Band {
  band: ReactNode;
  label: string;
  id: number;
}
export const bandsOfAspirants: Band[] = [
  {
    band: <span className="text-[#ED811E]">79,295</span>,
    label: 'Aspirants enrolled till now',
    id: 1,
  },
  {
    band: <span className="text-[#239D99]">97.3%</span>,
    label: 'Aspirants Got 7+ bands',
    id: 2,
  },
];

export interface Advantage {
  id: number;
  name: string;
  status: boolean;
  advantage?: string;
  disadvantage?: string;
}
export const advantagesData: Advantage[] = [
  { id: 1, name: 'Flexible Schedule', status: true },
  { id: 2, name: 'Work-Study Balance', status: true },
  {
    id: 3,
    name: 'Personal Attention',
    status: true,
    disadvantage: 'Huge Batch Size',
  },
  { id: 4, name: 'Band Predictor Test', status: true },
  {
    id: 5,
    name: 'Personalised Feedback',
    status: true,
    disadvantage: 'Generic Feedback',
  },
];

export interface StepType {
  stepNumber: number;
  title: string;
  subtitle: string;
}
export const masterclassSteps: StepType[] = [
  {
    stepNumber: 1,
    title: '🖥 Attend a class with India’s top instructors',
    subtitle:
      'Get the best of both worlds by attending our interactive and personalized session with only 5 students, from the comfort of your home and at your preferred time. A new class starts every 15 mins!',
  },
  {
    stepNumber: 2,
    title: '✍🏻 Take the Band Predictor Test',
    subtitle: 'Know where your preparation stands and identify your weak areas',
  },
  {
    stepNumber: 3,
    title: '👩🏻‍💻 Expert Evaluation and Feedback',
    subtitle:
      'After the test, get 1 on 1 personalised feedback from your instructor. Learn strategies, tips and tricks, based on your performance',
  },
  {
    stepNumber: 4,
    title: '😌 Ace IELTS with confidence',
    subtitle:
      'Apply your learnings and score a 7+ IELTS band in your 1st attempt.',
  },
];
export interface SelfPrepBannerData {
  heading: string | ReactNode;
  image: string;
  mobImage: string;
  sellingPoints: {
    img: string;
    text: string | ReactNode;
  }[];
}

export const selfPrepBannerData: SelfPrepBannerData = {
  heading: (
    <p>
      Why should you consider
      <span className="text-[#4936C1]"> LeapScholar?</span>
    </p>
  ),
  sellingPoints: [
    {
      img: `${imageBasePath}/avataran_assets/icons/succes_tick.svg`,
      text: (
        <p>
          <b>2,50,000+</b> Students Trained
        </p>
      ),
    },
    {
      img: `${imageBasePath}/avataran_assets/icons/succes_tick.svg`,
      text: (
        <p>
          <b> 90% Students</b> Score a 7+ Band
        </p>
      ),
    },
    {
      img: `${imageBasePath}/avataran_assets/icons/succes_tick.svg`,
      text: (
        <p>
          <b> 4.7/5</b> Average Student Rating
        </p>
      ),
    },
    {
      img: `${imageBasePath}/avataran_assets/icons/succes_tick.svg`,
      text: (
        <p>
          <b>2M+</b> App Downloads
        </p>
      ),
    },
  ],
  image: `${imageBasePath}/avataran_assets/images/demopage/app_banner.webp`,
  mobImage: `${imageBasePath}/avataran_assets/images/demopage/app_bannermweb.webp`,
};

export const heroBannerData = {
  title: (
    <div className="mb-6 md:mb-0">
      <p className="text-[20px] md:text-[24px] text-[#FFF] font-bold text-center">
        Get 7+ Band with Leap’s
      </p>
      <h1 className="text-[28px] md:text-[32px] bg-[linear-gradient(135deg,#FFC327_0%,#DA7238_100%)] bg-clip-text text-transparent font-bold md:text-center">
        Free IELTS Masterclass
      </h1>
      <h4 className="text-[14px] md:text-[18px] text-[#FFF] md:text-center">
        India’s top teachers with 10+ years of experience
      </h4>
    </div>
  ),
  imageUrl: '/avataran_assets/images/demopage/teachers.webp',
  mwebImageUrl: '/avataran_assets/images/demopage/teachersmweb.webp',
  bands: <Bands bands={bandsOfAspirants} />,
};

export const testimonialsCompData = {
  title: (
    <span>
      We’ve helped <span className="text-[#4c5afe]">120,321</span> people score
      8+ bands
    </span>
  ),
};

export const advantagesCompData = {
  title: (
    <h3 className="font-bold text-[24px] md:text-[32px] text-center">
      What makes <span className="text-[#4c5afe]">LeapScholar</span> the right
      choice for you
    </h3>
  ),
};

export const masterClassStepsCompData = {
  title: (
    <h3 className="text-[40px] text-[#0A0926] font-bold max-w-[22rem] pt-14">
      How does the{' '}
      <span className="bg-[linear-gradient(169deg,_#635EFF_0%,#5200FF_100%)] bg-clip-text text-transparent">
        FREE
      </span>{' '}
      Masterclass work? 🤔
    </h3>
  ),
  steps: masterclassSteps,
};
export const teachersListData = {
  title: (
    <h2 className="font-bold text-[24px] md:text-[40px] text-center">
      Learn from the Best IELTS Trainers in India{' '}
    </h2>
  ),
};
