import React from 'react';
import CourseCard from 'components/Ielts/SelfPrepPrePayment/SelfPrepCourses/CourseCard';
import { courseCardArray } from 'components/Ielts/SelfPrepPrePayment/DataMapper';
import style from 'public/static/scss/MasterclassType.module.scss';

const CourseWrapper = () => {
  return (
    <div
      className={`flex w-full justify-center overflow-scroll self-end pl-4 pr-1 ${style.hideScroll}`}
    >
      <div className="flex gap-4 w-full my-4 justify-start md:justify-center pr-4">
        {courseCardArray.length &&
          courseCardArray.map((courseCardData, index) => (
            <div
              key={`CourseCardArray${index}`}
              className={`hover:scale-105 cursor-pointer ${
                courseCardData.recomended ? ' order-[-1] md:order-none' : ''
              } ${index == courseCardArray.length - 1 ? 'pr-4 md:pr-0' : ''}`}
              onClick={courseCardData.ctaClick}
            >
              <CourseCard courseCardData={courseCardData} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default CourseWrapper;
