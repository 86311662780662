import { BenefitsArray } from 'DataMapper/IeltsSection/featueSection';
import { FunctionComponent } from 'react';
import BenefitCard from 'components/LSRevamp/ieltsPage/IeltsBenefits/BenefitCard';

interface Props {
  heading: string;
  benefitsArray: Array<BenefitsArray>;
}
const IeltsBenefits: FunctionComponent<Props> = ({
  heading,
  benefitsArray,
}) => {
  return (
    <div className="text-center py-10 flex flex-col gap-6 md:gap-24">
      <h2 className="text-xl md:text-4xl font-bold">{heading}</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-10 md:gap-y-12">
        {benefitsArray.map((benefitData, index) => (
          <BenefitCard benefitData={benefitData} key={`IeltsBenefit${index}`} />
        ))}
      </div>
    </div>
  );
};

export default IeltsBenefits;
