import Image from 'next/image';
import React, { FunctionComponent } from 'react';

interface Props {
  text: string;
  img: string;
  tagBg?: string;
}
const CourseTag: FunctionComponent<Props> = ({ text, img, tagBg }) => {
  return (
    <div
      className="flex py-2 px-4 rounded-xl items-center gap-1 w-fit cursor-default"
      style={{ background: tagBg ? tagBg : '#6DA559' }} //dynamic bg color
    >
      <Image src={img} alt="icon" height={16} width={16} />
      <p className="text-white text-xs font-bold">{text}</p>
    </div>
  );
};

export default CourseTag;
