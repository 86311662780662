import React, { FunctionComponent, ReactNode } from 'react';
import CourseWrapper from 'components/Ielts/SelfPrepPrePayment/SelfPrepCourses/CourseWrapper';
import { Button } from 'components/LSRevamp/common/Button2';
import PromoTag from 'components/Ielts/SelfPrepPrePayment/SelfPrepCourses/PromoTag';

interface Props {
  heading: string | ReactNode;
  subHeading?: string;
  bottomText?: string;
  ctaClick: () => void;
  ctaText: string;
  showPromoTag: boolean;
  promoTagData: {
    img: string;
    text: ReactNode;
  };
  secondaryCtaText: string;
  secondaryCtaClick: () => void;
}
const SelfPrepCourses: FunctionComponent<Props> = ({
  heading,
  subHeading,
  bottomText,
  ctaText,
  ctaClick,
  showPromoTag,
  promoTagData,
  secondaryCtaText,
  secondaryCtaClick,
}) => {
  return (
    <div
      className="flex flex-col gap-8 items-center relative py-10 bg-[linear-gradient(180deg,rgba(255,255,255,1)_12%,rgba(238,238,255,1)_60%,rgba(255,255,255,1)_60%)]"
      id="SelfPrepCourses"
    >
      <div className="flex flex-col gap-5 z-2 cursor-default px-4">
        <div className="text-[#101828] font-bold text-2xl md:text-[42px] text-center">
          {heading}
        </div>
        {subHeading && (
          <p className="text-[#1B1D6A] text-center md:text-lg opacity-80">
            {subHeading}
          </p>
        )}
      </div>
      {showPromoTag && (
        <div className="px-4">
          <PromoTag text={promoTagData.text} img={promoTagData.img} />
        </div>
      )}

      <CourseWrapper />

      {bottomText && (
        <p className="text-[#1B1D6A] opacity-80 text-center md:font-bold md:text-[#0A0926] md:opacity-70 px-4">
          {bottomText}
        </p>
      )}
      <div className="flex flex-col md:flex-row gap-4 w-full justify-center items-center px-4">
        <Button
          variant="gradient_blue"
          onClick={ctaClick}
          customCss="w-full md:w-fit py-3 text-base"
        >
          <p className="px-6">{ctaText}</p>
        </Button>
        <Button
          variant="bgNone"
          onClick={secondaryCtaClick}
          customCss="w-full md:w-fit py-3 text-base"
        >
          <p className="px-6">{secondaryCtaText}</p>
        </Button>
      </div>
    </div>
  );
};

export default SelfPrepCourses;
