import { BenefitsArray } from 'DataMapper/IeltsSection/featueSection';
import useDevice from 'hooks/useDevice';
import Image from 'next/image';
import React, { FunctionComponent } from 'react';

interface Props {
  benefitData: BenefitsArray;
}
const BenefitCard: FunctionComponent<Props> = ({ benefitData }) => {
  const { heading, subHeading, image } = benefitData;
  const { isMobile } = useDevice();
  return (
    <div className="flex flex-col items-center justify-start gap-3 h-full">
      <div>
        <Image
          src={image}
          height={isMobile ? 55 : 80}
          width={isMobile ? 55 : 80}
          alt={'image'}
        />
      </div>
      <div className="flex flex-col gap-1 text-center">
        {heading && (
          <h4 className="font-bold text-sm md:text-xl text-[#101828]">
            {heading}
          </h4>
        )}
        {subHeading && (
          <p className="text-[#667085] text-sm md:text-xl hidden md:block">
            {subHeading}
          </p>
        )}
      </div>
    </div>
  );
};

export default BenefitCard;
