import {
  FaqItem,
  IELTSPreparationData,
} from 'DataMapper/IeltsSection/ieltsFaq';
import Image from 'next/image';
import React, { FunctionComponent, useState } from 'react';

interface Props {
  content: IELTSPreparationData;
}

const SeoContent: FunctionComponent<Props> = ({ content }) => {
  const { contentfaq } = content;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <div className="bg-[#F7F7F7] p-4 md:py-8 md:px-20 section rounded-lg leading-[28px] text-[16px]">
      <div
        className={`flex flex-col justify-center gap-6 ${
          isExpanded ? 'block' : 'hidden'
        }`}
      >
        {Array.isArray(contentfaq) &&
          contentfaq?.length &&
          contentfaq?.map((content: FaqItem, idx: number) => {
            const { key, value } = content;
            return (
              <div key={idx} className="flex flex-col gap-6">
                <h2>{key}</h2>
                <div
                  className="flex flex-col gap-6"
                  dangerouslySetInnerHTML={{
                    __html: value || '',
                  }}
                />
              </div>
            );
          })}
      </div>
      <div className={`${isExpanded ? 'hidden' : 'block'}`}>
        <h2>
          {Array.isArray(contentfaq) &&
            contentfaq?.length &&
            contentfaq[0]?.key}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html:
              (Array.isArray(contentfaq) &&
                contentfaq?.length &&
                contentfaq[0]?.value) ||
              '',
          }}
        />
      </div>
      <button
        className="text-[#443EFF] font-bold mt-5 flex items-center gap-2 w-full justify-center"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <p>{isExpanded ? 'Read less' : 'Read More'}</p>

        <Image
          src="/avataran_assets/icons/blue_down_arrow.svg"
          height={15}
          width={15}
          className={`${isExpanded ? '' : 'rotate-180'}`}
          alt={'arrow'}
        />
      </button>
      <style jsx>{`
        .section {
          text-align: center;
          line-height: 28px;
        }
        .section :global(ul li) {
          list-style: disc;
          list-style-position: inside;
          font-weight: 400;
          font-size: 14px;
        }

        .section :global(a) {
          span {
            opacity: 1 !important;
            color: #443eff !important;
          }
        }

        .section h2 {
          font-weight: 700;
          font-size: 18px;
          margin: 12px 0px !important;
        }
        .section p {
          font-weight: 400;
          font-size: 14px;
        }
        @media screen and (min-width: 700px) {
          .section h2 {
            font-size: 20px;
          }
          .section p {
            font-size: 16px;
          }
          .section :global(ul li) {
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default SeoContent;
