import { Band } from 'components/DemoLandingPage/DemoPageData';
import React from 'react';

type Props = {
  bands: Band[];
};

const Bands: React.FC<Props> = ({ bands }) => {
  return (
    <div className="bg-white  rounded-[2.5rem] px-[1.15625rem] py-[0.625rem] flex  md:gap-x-4">
      {bands.map((item) => (
        <div key={item.id} className="px-2 md:px-9">
          <p className="text-[1rem] md:text-[1.5rem] text-center font-bold ">
            {item.band}
          </p>
          <p className="text-[12px] md:text-[0.93rem] text-center text-[#424242]">
            {item.label}
          </p>
        </div>
      ))}
    </div>
  );
};
export default Bands;
