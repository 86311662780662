import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
  text: string | ReactNode;
  img: string;
}
const PromoTag: FunctionComponent<Props> = ({ text, img }) => {
  return (
    <div className="flex bg-[linear-gradient(90.61deg,#F9E1FF_0%,#FFECD0_100%)] px-6 md:px-12 w-full gap-4 rounded-2xl items-center relative border border-[#F7F0FF] md:w-fit z-2">
      <img
        src={img}
        alt="party"
        className="relative top-[-15px] md:top-[-20px] md:w-[70px] md:h-16"
      />
      <div className="text-[#331C08] text-sm md:text-base my-3 md:my-5">
        {text}
      </div>
    </div>
  );
};

export default PromoTag;
