import { navRoutes } from 'page_routes';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DemoProgressiveForm from 'components/LandingPagesComponents/DemoProgressiveFormWrapper/DemoProgressiveForm';
import { ieltsPlusActions } from 'components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusActions';
import { FunctionComponent } from 'preact';
import useDevice from 'hooks/useDevice';
import {
  FormVersions,
  IeltsEliteFormFields,
  ieltsEliteFormRequiredFields,
} from 'components/DemoForm/demoFormJson';
import {
  QeFormData,
  UserInfoSchema,
} from 'Interfaces/reducerTypes/IeltsPlusSchema';
import Image from 'next/image';
import { isLoggedIn } from 'utils/loginUtils/loginFlowUtils';
import { useRouter } from 'next/router';
import {
  getByImageByFormVersion,
  getFormByFormVersion,
} from 'components/DemoForm/utils';

interface Props {
  ieltsDemoFormRef: MutableRefObject<any>;
  layoutCss?: string;
  layoutCss2?: string;
  redirectFormFilled?: boolean;
}
const DemoProgressiveFormWrapper: FunctionComponent<Props> = ({
  ieltsDemoFormRef,
  layoutCss = '',
  layoutCss2 = '',
  redirectFormFilled = true,
}) => {
  const [demoBookFormJson, setDemoBookFormJson] = useState<any | null>(null);
  const [prefilledData, setPrefilledData] = useState<Record<string, string>>(
    {},
  );
  const router = useRouter();
  const formVariant = router.query.form_version as string;
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const form_version = router.query.form_version;
  const getUserForm = (userDetails: UserInfoSchema) => {
    const prefilledData: Record<string, string> = {};
    if (userDetails?.phone)
      prefilledData[IeltsEliteFormFields.phone] = userDetails?.phone;
    if (userDetails?.email)
      prefilledData[IeltsEliteFormFields.emailId] = userDetails?.email;
    if (userDetails?.name)
      prefilledData[IeltsEliteFormFields.name] = userDetails?.name;
    if (prefilledData?.phone && prefilledData?.emailId && prefilledData?.name) {
      const filterFields = [
        IeltsEliteFormFields.emailId,
        IeltsEliteFormFields.name,
        IeltsEliteFormFields.phone,
        IeltsEliteFormFields.countryCode,
      ];
      const formData = getFormByFormVersion(form_version);

      if (form_version === FormVersions.ONBOARDING_QUESTION_ADD) {
        setDemoBookFormJson({
          ...formData,
          no_of_steps: 4,
          page_distribution: [3, 1],
          required: ieltsEliteFormRequiredFields.filter(
            (item) => !filterFields.includes(item),
          ),
          is_auto_progressive: true,
          fields: formData.fields.filter(
            (field: any) => !filterFields.includes(field.fieldValue),
          ),
        });
      } else {
        setDemoBookFormJson({
          ...formData,
          page_distribution: [3],
          is_auto_progressive: false,
          fields: formData.fields.filter(
            (field: any) => !filterFields.includes(field.fieldValue),
          ),
        });
      }

      setPrefilledData(prefilledData);
    } else {
      const formData = getFormByFormVersion(form_version);
      if (userDetails.phone) {
        setDemoBookFormJson({
          ...formData,
          fields: formData.fields.filter(
            (field: any) =>
              ![
                IeltsEliteFormFields.countryCode,
                IeltsEliteFormFields.phone,
              ].includes(field.fieldValue),
          ),
        });
        setPrefilledData({
          [IeltsEliteFormFields.phone]: userDetails.phone,
        });
      } else {
        setPrefilledData(formData);
      }
    }
  };
  const handleForFormFilled = (qeFormData: QeFormData) => {
    if (
      qeFormData?.ieltsExamType &&
      qeFormData.whatCareerStage &&
      qeFormData.whenIelts &&
      qeFormData.emailId &&
      qeFormData.name &&
      qeFormData.phone
    ) {
      window.open(navRoutes.IELTS_STUDENT_DASHBOARD, '_self');
    }
  };
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(ieltsPlusActions.fetchStudentDetails(getUserForm));
      if (redirectFormFilled) {
        dispatch(ieltsPlusActions.fetchQeFormData(handleForFormFilled));
      }
    } else {
      const formData = getFormByFormVersion(form_version);
      setDemoBookFormJson(formData);
    }
  }, [router?.query]);

  return demoBookFormJson ? (
    <div
      className={`relative pb-40 md:pb-10 ${layoutCss2}`}
      id="ielts-demo-booking-form"
      ref={ieltsDemoFormRef}
    >
      <div className={`md:pb-10 z-10 relative ${layoutCss} `}>
        {demoBookFormJson && (
          <DemoProgressiveForm
            formJson={demoBookFormJson}
            isLoggedIn={isLoggedIn}
            loggedInNumber={prefilledData?.phone}
            prefilledData={prefilledData}
          />
        )}
      </div>
      <Image
        src={getByImageByFormVersion(form_version, isMobile)}
        fill
        className="-z-10 "
        style={{
          objectFit: 'contain',
          objectPosition: 'bottom',
        }}
        alt="bgimg"
      />
    </div>
  ) : null;
};
export default DemoProgressiveFormWrapper;
