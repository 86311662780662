import { NextPage, NextPageContext } from 'next';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { fireTagEvent, logEvent, logPageView } from 'ga';
import Head from 'next/head';
import { Store } from 'redux';
import CounsellorInfoWrapper from 'components/Profile/StudentDashbord/IeltsSection/CounsellorInfoWrapper';
import FAQAccordion from 'components/LSRevamp/common/FAQAccordion';
import HeroWrapper from 'components/LSRevamp/ieltsPage/HeroWrapper';
import {
  courses,
  ieltsAccordionData,
  ieltsAccordionDataWithLinks,
  IELTSPreparationData,
  extractTextFromHTML,
  FaqItem,
} from 'DataMapper/IeltsSection/ieltsFaq';
import StudentTestimonialWrapper from 'components/LSRevamp/ieltsPage/StudentTestimonialWrapper';
import Layout from 'components/Common/Layout';
import { trackIeltsClick, trackPage } from 'scripts/segment';
import { ieltsPageName } from 'utils/events-utils';
import { eventCategory, eventName } from 'DataMapper/EventTracker/EventData';
import { upcomingIeltsBatchesAction } from 'components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsActions';
import { facultyActions } from 'components/Ielts/Faculty/redux/FacultyActions';
import { scrollToIeltsDemoForm } from 'utils/ielts_batch_utils';
import CourseSection from 'components/Ielts/CouseDetails/CourseSection';
import { useDispatch } from 'react-redux';
import IeltsBenefits from 'components/LSRevamp/ieltsPage/IeltsBenefits';
import { benefitsArray } from 'DataMapper/IeltsSection/featueSection';
import SelfPrepCourses from 'components/Ielts/SelfPrepPrePayment/SelfPrepCourses';
import { selfPrepCourseData } from 'components/Ielts/SelfPrepPrePayment/DataMapper';
import SeoContent from 'components/LSRevamp/ieltsPage/SeoContent';
import { navRoutes } from 'page_routes';
import { cookiesConstant, saveCookie } from 'utils/cookie-utiils';
import { facultyApis } from 'components/Ielts/Faculty/redux/FacultyApi';
import { TeacherSchema } from 'Interfaces/reducerTypes/TeacherSchema';
import { reviewApis } from 'components/Ielts/Reviews/redux/ReviewsApis';
import { ReviewDataSchema } from 'Interfaces/reducerTypes/ReviewSchema';
import { getTestimonialFromReviewData } from 'utils/reviewUtils';
import { facultyDataParser } from 'utils/facultyUtils';
import TestimonialsV2 from 'components/DemoLandingPage/TestimonialsV2';
import { testimonialsCompData } from 'components/DemoLandingPage/DemoPageData';
import AppBanner from 'components/DemoLandingPage/AppBanner';
import DemoProgressiveFormWrapper from 'components/LandingPagesComponents/DemoProgressiveFormWrapper';
import useLocation from 'hooks/useLocation';
import { checkIfMENAUser } from 'utils/countryUtils';
import { FormVersions } from 'components/DemoForm/demoFormJson';

export interface MyPageContext extends NextPageContext {
  store: Store;
  isServer: boolean;
}

const faqData = ieltsAccordionDataWithLinks.map(({ header, content }: any) => ({
  title: header,
  content,
}));

const faqDataFromCms = (cmsData: FaqItem[]) => {
  const faqDataList = cmsData?.map((data: FaqItem) => {
    return {
      title: data.key,
      content: extractTextFromHTML(data?.value || ''),
    };
  });
  return faqDataList || [];
};

interface Props {
  facultyData: Array<TeacherSchema>;
  scoContentData: IELTSPreparationData;
  testimonialData: Array<ReviewDataSchema>;
}

const CrashCourse: NextPage<Props> = ({
  facultyData,
  testimonialData,
  scoContentData,
}) => {
  const { meta_data, faqList, faqCtaText, faqHeading } = scoContentData;
  const faqCmsData = faqDataFromCms(faqList);
  const faqDataList = faqCmsData?.length ? faqCmsData : faqData;
  const router = useRouter();
  const { userCountry } = useLocation();
  const [showMenaForm, setShowMenaForm] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userDetailsFormRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    dispatch(upcomingIeltsBatchesAction.fetchUpcomingBatchPriceAndTime());
  }, []);

  const eventCapture = () => {
    logEvent(
      eventCategory.IELTS_WEBSITE,
      eventName.CRASHCOURSE_LANDINGPAGE,
      '',
    );
  };

  const saveGtm = async () => {
    await fireTagEvent('crash_course_landing_page');
  };

  useEffect(() => {
    trackPage(ieltsPageName.IELTS_CRASH_COURSE_PAGE);
    eventCapture();
    logPageView(router.asPath);
    saveGtm();
    if (document?.referrer) {
      saveCookie(
        null,
        cookiesConstant.IELTS_COURSE_REFFERER,
        document.referrer,
      );
    }
  }, []);
  useEffect(() => {
    if (router.query.form_version === FormVersions.MENA_FORM) {
      setShowMenaForm(true);
    }
  }, [router.query]);

  return (
    <>
      <Head>
        <title>
          {meta_data?.title ||
            'IELTS Online Preparation: Get 7+ Band In First Attempt | LeapScholar'}
        </title>
        <meta
          property="og:image"
          content="https://leapassets.s3.ap-south-1.amazonaws.com/ielts-recording/1619511191304-logo@2x_(1)_(1).png"
        />
        <meta
          name="description"
          content={
            meta_data?.description ||
            'Get 7+ band score with our IELTS online preparation course. Our interactive course covers all four IELTS modules: reading, writing, speaking and listening. Learn from qualified IELTS tutors, take mock tests and get live feedback on your performance to maximise your score with LeapScholar.'
          }
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              url: 'https://leapscholar.com/',
              logo: 'https://leapscholar.com/assets/company_logo/new-header-logo.svg',
              name: 'LeapScholar',
              alternateName: 'Leap Scholar - Study Abroad',
              description:
                'Want to Study Abroad? LeapScholar provides Personalized Guidance and information on top universities, courses, IELTS Coaching, Financial Help and Visa Assurance. Get Counselling and Mentorship for your Study abroad dream Today!',
              foundingDate: '2020',
              knowsAbout:
                'Study Abroad, Study Abroad consultant, Free Study Abroad Counselling',
              sameAs: [
                'https://www.youtube.com/c/leapscholar',
                'https://twitter.com/leapscholar',
                'https://www.linkedin.com/company/leap-global-education/',
                'https://www.instagram.com/leapscholar',
                'https://www.facebook.com/leapscholar',
                'https://t.me/leap_abroad',
              ],
              brand: {
                '@type': 'Brand',
                name: 'LeapScholar',
                alternateName: 'Leap Scholar - Study Abroad',
              },
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'Customer Support',
                telephone: '+91 8047184844',
                availableLanguage: ['English', 'Hindi'],
                email: 'contact@leapscholar.com',
              },
            }),
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              name: 'LeapScholar',
              alternateName: 'Leap Scholar - Study Abroad',
              url: 'https://leapscholar.com/',
            }),
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Course',
              mainEntity: courses.map((courseName) => {
                return {
                  '@type': 'Course',
                  name: courseName,
                };
              }),
            }),
          }}
          type="application/ld+json"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: faqDataList?.map(({ title, content }) => {
                return {
                  '@type': 'Question',
                  name: title,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: content,
                  },
                };
              }),
            }),
          }}
          type="application/ld+json"
        />
      </Head>
      <Layout>
        <HeroWrapper
          userDetailsFormRef={userDetailsFormRef}
          heading={scoContentData?.heading}
          description={scoContentData?.description}
          ctaBtnText={scoContentData?.ctaBtnText}
        />
        {showMenaForm ? (
          <DemoProgressiveFormWrapper
            ieltsDemoFormRef={userDetailsFormRef}
            layoutCss="!z-0"
            redirectFormFilled={false}
          />
        ) : (
          <iframe
            id="user-details-form"
            src="/ielts-preparation-online/ielts-elite-demo/user-details-form?leadSource=IELTS Landing Page"
            width="98%"
            className="min-w-fit overflow-hidden min-h-full"
            height="703em"
            ref={userDetailsFormRef}
          ></iframe>
        )}
        <div className="md:w-[80%] m-auto p-4 md:p-0 md:mt-10">
          <IeltsBenefits
            heading="Benefits of Leap’s  IELTS Online Courses"
            benefitsArray={benefitsArray}
          />
        </div>
        <SelfPrepCourses
          {...selfPrepCourseData}
          ctaClick={() => {
            trackIeltsClick(ieltsPageName.IELTS_CRASH_COURSE_PAGE, {
              contentName: 'View All Batches',
              contentFormat: 'Button',
              widgetName: 'Primary CTA',
              widgetFormat: 'Button',
            });
            window.open(navRoutes.IELTS_ALL_BATCHES, '_self');
          }}
          secondaryCtaClick={() => {
            trackIeltsClick(ieltsPageName.IELTS_CRASH_COURSE_PAGE, {
              contentName: 'Book a demo',
              contentFormat: 'Button',
              widgetName: 'Secodary CTA',
              widgetFormat: 'Button',
            });
            scrollToIeltsDemoForm(userDetailsFormRef);
          }}
        />
        <CounsellorInfoWrapper severFacultyData={facultyData} />
        <TestimonialsV2
          {...testimonialsCompData}
          testimonialDataProps={testimonialData
            ?.map((item) => ({
              name: item.Name || '',
              reviews: item.review || '',
              image: item.user_image?.url || '',
              college: item.score || '',
              rating: item?.rating || '4',
              score: item?.score?.slice(13, 16),
              ratingText: 'A week ago',
            }))
            .slice(0, 20)}
        />
        {!checkIfMENAUser(userCountry) && <AppBanner />}
        <div className="bg-[#F3F4FF] pt-8 md:pt-0">
          <FAQAccordion
            data={faqDataList}
            buttonText={faqCtaText || 'Book a MasterClass'}
            trackingEvent={() => {
              trackIeltsClick(ieltsPageName.IELTS_CRASH_COURSE_PAGE, {
                contentName: 'Book a demo',
                contentFormat: 'Button',
                widgetName: 'FAQ CTA',
                widgetFormat: 'Button',
              });
            }}
            faqHeading={faqHeading}
            faqCtaHandler={() => {
              scrollToIeltsDemoForm(userDetailsFormRef);
            }}
            isCtaFixed
          />
        </div>
        {!checkIfMENAUser(userCountry) && (
          <div className="md:w-4/5 m-auto p-4 md:p-0 md:my-10">
            <SeoContent content={scoContentData} />
          </div>
        )}
      </Layout>
    </>
  );
};

export async function getStaticProps() {
  const [facultyRes, reviewRes, seoContentRes] = await Promise.all([
    facultyApis.fetchFaculties(),
    reviewApis.fetchReviewOnServer(),
    reviewApis.fetchSeoContent(),
  ]);
  const facultyData = facultyDataParser(facultyRes) || [];
  const testimonialData = reviewRes.success
    ? getTestimonialFromReviewData(reviewRes.data)
    : [];
  const scoContentData = seoContentRes?.success ? seoContentRes?.data : {};
  return {
    props: {
      facultyData,
      scoContentData,
      testimonialData,
    },
    revalidate: 60 * 60 * 24, // 24 hours
  };
}

export default CrashCourse;
