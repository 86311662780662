import { ReactNode } from 'react';

export enum CourseType {
  ULTIMATE = 'ULTIMATE',
  ELITE = 'ELITE',
  SELECT = 'SELECT',
  GRAMMER = 'GRAMMER',
  TARGET_IELTS = 'TARGET_IELTS',
  CRASH_COURSE = 'CRASH_COURSE',
  ONE_ON_ONE = 'ONE_ON_ONE',
  SELF_PREP = 'SELF_PREP',
}
export interface CourseCardData {
  headingImage?: string;
  heading: string;
  subHeading?: string;
  displayPrice?: string;
  coursePrice?: number;
  couponData?: {
    couponText: string;
    couponCta: string;
  };
  sellingPoints: {
    img: string;
    text: string | ReactNode;
  }[];
  ctaText?: string;
  ctaClick?: () => void;
  courseType: CourseType;
  recomended?: boolean;
  recomendedBg?: string;
}

export interface SelfPrepCoursesdata {
  heading: string | ReactNode;
  ctaText: string;
  subHeading?: string;
  ctaClick: () => void;
  showPromoTag: boolean;
  promoTagData: {
    img: string;
    text: ReactNode;
  };
  secondaryCtaText: string;
  secondaryCtaClick: () => void;
}
