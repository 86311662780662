import {
  CourseCardData,
  CourseType,
  SelfPrepCoursesdata,
} from 'components/Ielts/SelfPrepPrePayment/DataMapper/intefaces';
import { imageBasePath } from 'utils/imageUtils';
import { navRoutes } from 'page_routes';
import { trackIeltsClick } from 'scripts/segment';
import { ieltsPageName } from 'utils/events-utils';

export const TICK_IMAGE = `${imageBasePath}/avataran_assets/icons/green_tick_bg_none.svg`;

export const gradientCircles = {
  greenPurple: `${imageBasePath}/avataran_assets/icons/gradient_circles/green_purple.png`,
  orangeGray: `${imageBasePath}/avataran_assets/icons/gradient_circles/orange_gray.png`,
  orangeYellow: `${imageBasePath}/avataran_assets/icons/gradient_circles/orange_yellow.png`,
  pinkYellow: `${imageBasePath}/avataran_assets/icons/gradient_circles/pink_yellow.png`,
  pink: `${imageBasePath}/avataran_assets/icons/gradient_circles/pink.png`,
  purpleGray: `${imageBasePath}/avataran_assets/icons/gradient_circles/purple_gray.png`,
  yellowGray: `${imageBasePath}/avataran_assets/icons/gradient_circles/yellow_gray.png`,
};

const selfPrepUltimateData: CourseCardData = {
  headingImage: gradientCircles.pinkYellow,
  heading: 'IELTS Boost',
  subHeading: 'Self Paced Learning',
  ctaClick: () => {
    trackIeltsClick(ieltsPageName.IELTS_CRASH_COURSE_PAGE, {
      contentName: 'View ielts boost',
      contentFormat: 'Button',
      widgetName: 'Hover CTA',
      widgetFormat: 'Button',
    });
    window.open(navRoutes.IELTS_SELF_PREP, '_blank');
  },
  sellingPoints: [
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>10 </b>
          hours of Recorded IELTS Training
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>5 </b>Full Length IELTS Simulation Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>8</b> Full Length Speaking Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>15</b> Reading Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>15</b> Listening Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>15</b> Writing Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: <p>Daily Live Grammar Classes</p>,
    },
    {
      img: TICK_IMAGE,
      text: <p>Daily Live Doubt-Solving Classes</p>,
    },
  ],
  courseType: CourseType.SELF_PREP,
};

export const ieltsFocusData = {
  headingImage: gradientCircles.orangeYellow,
  heading: 'IELTS Focus',
  subHeading: 'Batch Size of 10 students',
  ctaClick: () => {
    trackIeltsClick(ieltsPageName.IELTS_CRASH_COURSE_PAGE, {
      contentName: 'View ielts focus',
      contentFormat: 'Button',
      widgetName: 'Hover CTA',
      widgetFormat: 'Button',
    });
    window.open(navRoutes.IELTS_ALL_BATCHES, '_blank');
  },
  sellingPoints: [
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>20</b> hours of Live IELTS Learning
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>3</b> Full Length IELTS Simulation Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>12 </b>
          Full Length Speaking Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>25 </b>
          Reading Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>25 </b>
          Listening Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>25 </b>
          Writing Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: <p>Band Assurance for all batches</p>,
    },
    {
      img: TICK_IMAGE,
      text: <p>Daily Live Grammar Classes</p>,
    },
    {
      img: TICK_IMAGE,
      text: <p>Daily Live Doubt-Solving Classes</p>,
    },
  ],
  courseType: CourseType.SELECT,
};

export const ieltsPrimeData = {
  headingImage: gradientCircles.greenPurple,
  heading: 'IELTS Prime',
  subHeading: 'Batch Size of 10 students',
  ctaClick: () => {
    trackIeltsClick(ieltsPageName.IELTS_CRASH_COURSE_PAGE, {
      contentName: 'View ielts prime',
      contentFormat: 'Button',
      widgetName: 'Hover CTA',
      widgetFormat: 'Button',
    });
    window.open(navRoutes.IELTS_ALL_BATCHES, '_blank');
  },
  sellingPoints: [
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>30</b> hours of Live IELTS Learning
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>5</b> Full Length IELTS Simulation Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>18 </b>
          Full Length Speaking Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>25 </b>
          Reading Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>25 </b>
          Listening Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: (
        <p>
          <b>25 </b>
          Writing Tests
        </p>
      ),
    },
    {
      img: TICK_IMAGE,
      text: <p>Band Assurance for all batches</p>,
    },
    {
      img: TICK_IMAGE,
      text: <p>Daily Live Grammar Classes</p>,
    },
    {
      img: TICK_IMAGE,
      text: <p>Daily Live Doubt-Solving Classes</p>,
    },
  ],
  courseType: CourseType.ELITE,
  recomendedBg: '#5CB9B6',
};
export const courseCardArray: Array<CourseCardData> = [
  selfPrepUltimateData,
  ieltsFocusData,
  ieltsPrimeData,
];

export const selfPrepCourseData: SelfPrepCoursesdata = {
  heading: (
    <h2>
      LeapScholar's
      <span className="text-[#5200FF]"> IELTS </span>
      Courses
    </h2>
  ),
  ctaText: 'View Upcoming Batches',
  subHeading: "Choose your path to success with LeapScholar's Courses",
  ctaClick: () => {},
  showPromoTag: true,
  promoTagData: {
    img: `${imageBasePath}/avataran_assets/icons/party_confetti.svg`,
    text: (
      <p>
        Apply <b>IELTS10</b> for <b>10% Discount</b> on all Courses
      </p>
    ),
  },
  secondaryCtaText: 'Book a Free Session',
  secondaryCtaClick: () => {},
};
