import React, { FunctionComponent } from 'react';
import CourseTag from 'components/Ielts/SelfPrepPrePayment/SelfPrepCourses/CourseTag';
import { CourseCardData } from 'components/Ielts/SelfPrepPrePayment/DataMapper/intefaces';
import { Button } from 'components/LSRevamp/common/Button2';
import { imageBasePath } from 'utils/imageUtils';
import Image from 'next/image';

interface Props {
  courseCardData: CourseCardData;
}
const CourseCard: FunctionComponent<Props> = ({ courseCardData }) => {
  const {
    headingImage,
    heading,
    subHeading,
    sellingPoints,
    ctaClick,
    ctaText,
    recomended,
    recomendedBg,
  } = courseCardData;
  return (
    <div
      className={`min-w-[312px] max-w-[400px] ${
        recomended
          ? 'border-2 border-[#5CB9B6] bg-white shadow-[0px_4px_6px_rgba(65,52,146,0.06),0px_4px_12px_rgba(65,52,146,0.12)]'
          : 'border border-[#E8E8E8] bg-[radial-gradient(145.59%_92.61%_at_50%_50%,#FFFFFF_25.04%,rgba(240,240,255,0.76)_100%)]'
      } rounded-2xl p-6 relative flex flex-col gap-4 h-full justify-between`}
    >
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          {headingImage && (
            <Image
              src={headingImage}
              alt="gradient circle"
              height={32}
              width={32}
            />
          )}
          <h3 className="font-bold text-xl text-[#0A0926]">{heading}</h3>
        </div>
        <p className="text-[#8E8E8E] text-sm md:text-base">{subHeading}</p>
        <div className="flex flex-col gap-2">
          <div className="w-full h-[1px] bg-[#F5F5FA] my-1" />
          <div className="flex flex-col gap-6">
            {sellingPoints.map(({ img, text }, index) => (
              <div key={`sellingPoints${index}`} className="flex gap-2">
                <img src={img} alt="tick" height={16} width={16} />
                <div className="text-[#424242]">{text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {ctaText && (
        <Button
          variant="gradient_orange"
          onClick={ctaClick}
          customCss="w-full py-3"
          size="small"
        >
          {ctaText}
        </Button>
      )}
      {recomended && (
        <div className="absolute top-[-15px] z-10 right-4">
          <CourseTag
            text="RECOMMENDED"
            img={`${imageBasePath}/avataran_assets/images/course/thumbs_up_green_border.svg`}
            tagBg={recomendedBg}
          />
        </div>
      )}
    </div>
  );
};

export default CourseCard;
